document.addEventListener('turbolinks:load', function(){
	$(document).scroll(function() {
		if ($(document).scrollTop() > 100) {
			$(".header").addClass("header__active");
		} else {
			$(".header").removeClass("header__active");
		}
	});

	$('.header__item-link, .poster-slider__btn, .logo, .chess_block__btn').on('click', function(e) {
		e.preventDefault();
		const anchorLick = $(this).data('link');
		if ($('section, footer').is(anchorLick)) {
			$('html, body').animate({
				scrollTop: $(anchorLick).offset().top  - 140
			}, 400);
			return false;
		} else {
			const pageAnchor = $(this).data('page');
			Turbolinks.visit(pageAnchor);
			document.addEventListener("turbolinks:load", function() {
				$('html, body').scrollTop($(anchorLick).offset().top - 140);
				return false;
			})
		}
	});

	const $mobileMenu = $('#mobile-menu');
	function showMenu() {
		$mobileMenu.toggleClass('active');
		$('.header__menu-col').slideToggle();
	}
	$mobileMenu.click(function() {
		showMenu();
	});
	$(document).mouseup(function (e) {
		const header = $("header");
		if ($mobileMenu.hasClass('active')) {
			if (!header.is(e.target) && header.has(e.target).length === 0) {
				showMenu()
			}
		}
	});

	$('.lang-block').on('click', '.lang-block__name', function () {
		const target = $(this);
		if (target.hasClass('lang-block__name')) {
			let url = this.dataset.url;
			if (!url) {
				const lang = this.dataset.lang;
				const loc = location.pathname;
				url = localeUrl(loc.replace(/^(?:\/[a-zA-Z]{2}(?=\/|$)|\/$|)/, ''), lang);
			}
			location = url + location.search + location.hash;
		}
	});
	function localeUrl(url, locale)	{
		locale = locale || language;
		return ((locale === 'uk' ? '' : '/' + locale) + url) || '/';
	}
});
