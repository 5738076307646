import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

$( document ).on('turbolinks:load', function()
{
	const posterSwiper = new Swiper('.poster-slider', {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false
		},
		loop: $('.poster-slider .swiper-slide').length > 1,
		watchOverflow: true,
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
	});
	const aboutSwiper = new Swiper('.about-slider', {
		navigation: {
			nextEl: ".about__button-next",
			prevEl: ".about__button-prev"
		},
		pagination: {
			el: ".about__pagination",
			clickable: true,
		}
		//loop: $('.about-slider .swiper-slide').length > 1
	});
	const gallerySwiper = new Swiper('.gallery__slider', {
		slidesPerView: 3,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: ".gallery__button-next",
			prevEl: ".gallery__button-prev"
		},
		pagination: {
			el: ".gallery__pagination",
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			740: {
				slidesPerView: 3,
				spaceBetween: 30
			}
		}
	});
});
