import "magnific-popup"

$( document ).on('turbolinks:load', function(){
	$('.gallery__slider').magnificPopup({
		delegate: 'a',
		type:'image',
		closeOnContentClick: false,
		closeBtnInside: false,
		image: {
			verticalFit: true
		},
		gallery: {
			enabled: true
		}
	});
});
